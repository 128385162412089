import React from 'react';

const Card = (props) => {

    return (
        <div className={`card ${props.classes}`}>
            {props.title &&
                <div className="card-heading">
                    {props.title}
                    {props.close &&
                        <span className="fa fa-close float-right clickable" onClick={props.close}></span>
                    }
                </div>
            }
            <div className="card-body">
                {props.children}
            </div>
        </div>

    );
};

export default Card;