import { createSlice } from '@reduxjs/toolkit';

export const INTRO = "intro";
export const PARTICIPANT_DETAILS = "participantDetails";
export const QUESTIONARE = "questionare";
export const THANK_YOU = "thankYou";
export const LOGGED_IN = "loggedin";

const initialState = {
    state: INTRO
}

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.state = action.payload.state;
        }
    }
});

export const { setState } = appStateSlice.actions;
export default appStateSlice.reducer;