import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ThankYou = () => {
    const questions = useSelector(x => x.questionare);
    const [wellnessScore, setWellnessScore] = useState(0);
    const [emailSent, setEmailSent] = useState(false);

    const { firstName, email } = useSelector(x => x.participant);

    useEffect(() => {
        if (questions) {
            let values = questions.map(x => x.value);
            let score = 0;
            values.forEach(val => {
                score += val;
            });

            setWellnessScore((score / 10));
        }
    }, [questions])

    const sendEmail = () => {
        let data = {
            firstName: firstName,
            email: email,
            wellnessNumber: wellnessScore
        }

        fetch('https://api.elearning.academyone.co.uk/api/wellness/emailme', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                setEmailSent(true);
            });
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <div className=' justify-items-center'>
                <p className='text-center mt-2 font-bold text-xl sm:text-3x'>Thank you {firstName}</p>
                <p className='mb-6 font-bold text-center text-xl sm:text-3x'>Your wellness number is:</p>
                <div className='max-w-[60%] mx-auto md:max-w-[90%]'>
                    <CircularProgressbar value={wellnessScore * 10} text={wellnessScore}
                        styles={buildStyles({
                            textSize: '30px',
                            textColor: '#000',
                        })}
                    />
                </div>
                {
                    emailSent ?
                        <>
                            <a href='https://www.ragefitnesscompany.com/'>
                                <button className='mt-6 flex shadow bg-red hover:bg-red-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mx-auto'>
                                    Visit Rage Fitness
                                </button>
                            </a>
                            <a href='https://thesustainabilityacademy.co.uk/'>
                                <button className='mt-4 flex shadow bg-tsa hover:bg-tsa-dark focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mx-auto'>
                                    Visit The Sustainability Academy
                                </button>
                            </a>
                        </>
                        :
                        <button onClick={() => sendEmail()}
                            className='mt-8 flex shadow bg-red hover:bg-red-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mx-auto'>
                            Email me my number
                        </button>
                }
            </div>
        </motion.div >
    );
};

export default ThankYou;