import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    organisation: '',
    department: ''
}

export const participantDetailsSlice = createSlice({
    name: 'participant',
    initialState,
    reducers: {
        updateParticipant: (state, action) => {
            state[action.payload.name] = action.payload.value;
        }
    }
});

export const { updateParticipant } = participantDetailsSlice.actions;
export default participantDetailsSlice.reducer;