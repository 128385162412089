import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

const Question = ({ question, one, ten, value, questionKey, handleChange }) => {

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <div className='flex justify-center mb-5 sm:p-6 font-bold text-2xl sm:text-3xl '>
                <div className='md:items-center text-center'>
                    <p className='question'>{question}</p>
                </div>
            </div>

            <div className='mx-auto'>
                <div className='slider mt-14'>
                    <Slider
                        id={questionKey}
                        min={1}
                        max={10}
                        step={1}
                        value={value}
                        onChange={handleChange}
                    />
                </div>

                <div className='gap-12 columns-2 flex justify-between mt-5'>
                    <p className='max-w-[45%] md:max-w-[35%] font-bold text-black text-sm md:text-base text-left'>{one}</p>
                    <p className='max-w-[45%] md:max-w-[35%] font-bold text-black text-sm md:text-base text-right'>{ten}</p>
                </div>
            </div>
        </motion.div >
    );
};

export default Question;