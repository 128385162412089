import React, { useState } from 'react';
import { store } from '../App/store';
import { useSelector } from 'react-redux';
import { updateParticipant } from '../App/Reducers/ParticipantDetailsSlice';
import { QUESTIONARE, setState } from '../App/Reducers/AppStateSlice';

const validEmailRegex =
    RegExp(/^((([^<>()[\],;:\s@"]+(.[^<>()[].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:s@"]{2,}))/g);

const ParticipantDetails = () => {

    const formInputs = useSelector(x => x.participant);


    const [errors, setErrors] = useState({
        organisation: '',
        email: '',
    })

    const handleChange = evt => store
        .dispatch(updateParticipant({ name: evt.target.name, value: evt.target.value }))

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        //Company
        if (!formInputs["organisation"]) {
            formIsValid = false;
            errors["organisation"] = "Organisation or School is required";
        }

        //Email
        if (!formInputs["email"]) {
            formIsValid = false;
            errors["email"] = "Email is required";
        } else {
            if (!validEmailRegex.test(formInputs["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid email address";
            }
        }

        setErrors(errors);
        return formIsValid;
    }


    return (
        <div>
            <div className="max-w-screen-md mx-auto p-5">
                <div className="text-center mb-16">
                    <p className="mt-4 text-sm sm:text-sm leading-7 text-gray-500 font-regular uppercase">
                        Your Details
                    </p>
                    <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
                        About <span className="text-red">You</span>
                    </h3>
                </div>

                <div className="w-full">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                First Name (optional)
                            </label>
                            <input value={formInputs.firstName} onChange={handleChange} name='firstName' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="First name" />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Last Name (optional)
                            </label>
                            <input value={formInputs.lastName} onChange={handleChange} name='lastName' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Last name" />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Organisation/School
                            </label>
                            <input value={formInputs.organisation} onChange={handleChange} name='organisation' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 md:mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Organisation/School" />
                            <p className="text-red-500 font-bold text-xs mt-2">{errors.organisation}</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Department/Year Group (if applicable)
                            </label>
                            <input value={formInputs.department} onChange={handleChange} name='department' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 md:mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Department/Year Group" />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Email Address (to send your results)
                            </label>
                            <input value={formInputs.email} onChange={handleChange} name='email' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" placeholder="Email address" />
                            <p className="text-red-500 font-bold text-xs mt-2">{errors.email}</p>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="flex justify-center w-full px-3">
                            <button onClick={() => {
                                if (handleValidation()) {
                                    store.dispatch(setState({ state: QUESTIONARE }))
                                }
                            }}
                                className="shadow bg-red hover:bg-red-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded">
                                Begin
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipantDetails;