import React from 'react';

const Table = ({ headings, rowData }) => {

    return (
        <table className="table-auto">
            <thead className="card-header">
                <tr>
                    {headings()}
                </tr>
            </thead>
            <tbody>
                {rowData()}
            </tbody>
        </table>
    );
};

export default Table;