import { configureStore } from '@reduxjs/toolkit';
import questionareReducer from './Reducers/QuestionareSlice';
import participantReducer from './Reducers/ParticipantDetailsSlice';
import appStateReducer from './Reducers/AppStateSlice';
import authReducer from './Reducers/AuthSlice';

export const store = configureStore({
    reducer: {
        questionare: questionareReducer,
        participant: participantReducer,
        appState: appStateReducer,
        auth: authReducer
    }
});