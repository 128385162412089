import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            if (action.payload.username === "admin" && action.payload.password === "Rage123!") {
                state.isLoggedIn = true;
            }
            else {
                state.isLoggedIn = false;
            }
        }
    }
});

export const { login } = authSlice.actions;
export default authSlice.reducer;