import React from 'react';
import { useSelector } from 'react-redux';
import Report from './Report';
import Unauthorised from './Unauthorised';

const Admin = () => {
    const { isLoggedIn } = useSelector(x => x.auth);

    const renderComponent = () => {
        switch (isLoggedIn) {
            case true:
                return <Report />
            case false:
                return <Unauthorised />
            default:
                return <Unauthorised />
        }
    }
    return (
        <div>
            {renderComponent()}
        </div>
    );
};

export default Admin;