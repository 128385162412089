export const QUESTIONS = [
    {
        key: 1,
        question: "If there was a mirror in front of you RIGHT NOW, what would you rate your appearance out of 10?",
        one: "Hate how I look.",
        ten: "Very sexy.",
        allowZero: false,
        value: 5
    },
    {
        key: 2,
        question: "How do you rate your own fitness levels?",
        one: "Couch Potato.",
        ten: "Athlete.",
        allowZero: false,
        value: 5
    },
    {
        key: 3,
        question: "How Healthy Do You feel?",
        one: "Always feel in poor health.",
        ten: "Healthiest I've ever felt.",
        allowZero: false,
        value: 5
    },
    {
        key: 4,
        question: "How Motivated are you to improve your overall physical & mental health?",
        one: "I'm too busy.",
        ten: "LET'S DO THIS!!!",
        allowZero: false,
        value: 5
    },
    {
        key: 5, question: "How do you rate your Will Power around anything in life?",
        one: "I need support to help myself improve physically, mentally and emotionally.",
        ten: "I don't need anyone to help me improve.",
        allowZero: false,
        value: 5
    },
    {
        key: 6,
        question: "If you feel you have it, what is your anxiety number?",
        one: "I constantly worry.",
        ten: "I'm a laid back Larry, never worry!",
        allowZero: false,
        value: 5
    },
    {
        key: 7,
        question: "If you feel you have it, what is your Depression number?",
        one: "I am always sad, never feel happy.",
        ten: "Nothing gets to me, always happy and smiley.",
        allowZero: false,
        value: 5
    },
    {
        key: 8,
        question: "Drinking/Smoking habits?",
        one: "I drink every day.",
        ten: "Never ever drink.",
        allowZero: false,
        value: 5
    },
    {
        key: 9,
        question: "What score would you give your overall Mental & Emotional Health?",
        one: "I struggle to wake up with a smile, I say I'm Fine a lot.",
        ten: "The best I've ever felt, I wake and jump out of bed.",
        allowZero: false,
        value: 5
    },
    {
        key: 10,
        question: "What score would you give overall Physical Health?",
        one: "Feel weak mostdays, don't feel strong and can't run very far.",
        ten: "The fittest and healthiest I've ever felt.",
        allowZero: false,
        value: 5
    }
]