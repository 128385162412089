import React from 'react';
import { PARTICIPANT_DETAILS, setState } from '../App/Reducers/AppStateSlice';
import { store } from '../App/store';

const Intro = () => {

    return (
        <div className='justify-center'>
            <video className="video-image"
                src="https://academyone.blob.core.windows.net/videos/survey%20video.mp4"
                controls
                autoPlay
                playsInline
                type='video/mp4'
            />
            <button className='flex shadow bg-red hover:bg-red-light focus:shadow-outline 
            focus:outline-none text-white font-bold py-2 px-6 rounded mt-14 mx-auto'
                onClick={() => { store.dispatch(setState({ state: PARTICIPANT_DETAILS })) }}>Get Started</button>
        </div>
    );
};

export default Intro;