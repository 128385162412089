import React from 'react';
import { INTRO, PARTICIPANT_DETAILS, QUESTIONARE, THANK_YOU } from '../App/Reducers/AppStateSlice';
import { useSelector } from 'react-redux';
import Intro from './Intro';
import ParticipantDetails from './ParticipantDetails';
import Questionare from './Questionare';
import ThankYou from './ThankYou';

const Wellness = () => {
    const { state } = useSelector(x => x.appState);

    const renderComponent = () => {
        switch (state) {
            case INTRO:
                return <Intro />
            case PARTICIPANT_DETAILS:
                return <ParticipantDetails />
            case QUESTIONARE:
                return <Questionare />
            case THANK_YOU:
                return <ThankYou />
            default:
                return <Intro />
        }
    }
    return (
        <>
            {renderComponent()}
        </>
    );
};

export default Wellness;