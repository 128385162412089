import axios from 'axios';

const base = `https://api.elearning.academyone.co.uk/api/Wellness`;

export const getAllWellnessResultsAsync = async () => {
    const response = await axios.get(`${base}`);

    return response.data;
}

export const getOrganisationsAsync = async () => {
    const response = await axios.get(`${base}/GetOrganisations`);
    return response.data;
}

export const getWellnessResultsByEmailAsync = async email => {
    const response = await axios.get(`${base}/Email/${email}`);
    return response.data;
}

export const getWellnessResultsByOrgAsync = async orgName => {
    const response = await axios.get(`${base}/org/${orgName}`);
    return response.data;
}