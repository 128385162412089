import axios from 'axios';
import download from 'downloadjs';

const controllerName = 'Report';

export const getWellnessOverviewAsync = async () => {

    const response = await axios.get(`https://api.elearning.academyone.co.uk/api/${controllerName}/wellness`).then(response => {
        const content = response.headers['content-type'];
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;
        const fileName = `wellness-results-${today}.csv`
        download(response.data, fileName, content);
        return true;
    })
        .catch(
            error => console.log(error)
        )

    return response;
}