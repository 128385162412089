import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Question from './Question';
import { store } from '../App/store';
import { answerQuestion } from '../App/Reducers/QuestionareSlice';
import { setState, THANK_YOU } from '../App/Reducers/AppStateSlice';

const Questionare = () => {
    const questions = useSelector(x => x.questionare);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const participant = useSelector(x => x.participant);

    const next = () => {
        let newNumber = currentQuestionNumber + 1;
        setCurrentQuestionNumber(newNumber);
    }

    const previous = () => {
        let newNumber = currentQuestionNumber - 1;
        setCurrentQuestionNumber(newNumber);
    }

    const handleChange = (value) => {
        store.dispatch(answerQuestion({ index: currentQuestionNumber, value: value }))
    }

    const submit = () => {
        let values = questions.map(x => x.value);
        let score = 0;
        values.forEach(val => {
            score += val;
        });

        let data = {
            participant: participant,
            questions: questions,
            wellnessNumber: (score / 10)
        }

        fetch('https://api.elearning.academyone.co.uk/api/wellness', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                store.dispatch(setState({ state: THANK_YOU }))
            });
    }

    return (
        <div className="max-w-[90%] min-w-[90%]">
            <Question
                key={questions[currentQuestionNumber].key}
                questionKey={questions[currentQuestionNumber].key}
                question={questions[currentQuestionNumber].question}
                one={questions[currentQuestionNumber].one}
                ten={questions[currentQuestionNumber].ten}
                value={questions[currentQuestionNumber].value}
                handleChange={handleChange}
            />
            <div>

            </div>
            <div className='flex justify-center mt-10'>
                {
                    currentQuestionNumber > 0 &&
                    <button className='shadow bg-white border-2 border-red hover:bg-zinc-50 focus:shadow-outline focus:outline-none text-red font-bold py-2 px-6 rounded mx-2' onClick={() => { previous() }}>Back</button>
                }
                {
                    currentQuestionNumber < 9 &&
                    <button className='shadow bg-red hover:bg-red-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mx-2' onClick={() => { next() }}>Next</button>
                }
                {
                    currentQuestionNumber === 9 &&
                    <button className='shadow bg-red hover:bg-red-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mx-2' onClick={() => { submit() }}>Submit</button>
                }
                {
                    questions[currentQuestionNumber].allowZero &&
                    <button className=' hover:text-black focus:shadow-outline focus:outline-none text-red underline underline-offset-1 font-bold py-2 px-6 rounded mx-2' onClick={() => {
                        store.dispatch(answerQuestion({ index: currentQuestionNumber, value: 0 }));
                        next();
                    }}>
                        Skip
                    </button>
                }
            </div>
        </div>
    );
};

export default Questionare;