import { createSlice } from '@reduxjs/toolkit';
import { QUESTIONS } from '../../Questions';

const initialState = QUESTIONS

export const questionareSlice = createSlice({
    name: 'questionare',
    initialState,
    reducers: {
        answerQuestion: (state, action) => {
            state[action.payload.index].value = parseInt(action.payload.value);
        }
    }
});

export const { answerQuestion } = questionareSlice.actions;
export default questionareSlice.reducer;