import React from 'react';
import { store } from '../App/store';
import { login } from '../App/Reducers/AuthSlice';

const Unauthorised = () => {


    const [formInputs, setFormInputs] = React.useState({ username: '', password: '' })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: value })
    }

    return (
        <>
            <div className="text-center mb-16">
                <p className="mt-4 text-sm sm:text-sm leading-7 text-gray-500 font-regular uppercase">
                    Admin
                </p>
                <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
                    <span className="text-red">Login</span>
                </h3>
            </div>
            <div className="w-full">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Username
                        </label>
                        <input value={formInputs.username} onChange={handleChange} name='username' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Username" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Password
                        </label>
                        <input value={formInputs.password} onChange={handleChange} name='password' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="password" placeholder="Password" />
                    </div>
                </div>
                <button className='flex shadow bg-red hover:bg-red-light focus:shadow-outline 
            focus:outline-none text-white font-bold py-2 px-6 rounded mt-14 mx-auto'
                    onClick={() => { store.dispatch(login(formInputs)) }}>Login</button>
            </div>
        </>
    );
};

export default Unauthorised;