import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import RageLogo from './Assets/Images/rage.png';
import TSALogo from './Assets/Images/TSA1.png';
import Admin from './Components/Admin';
import Wellness from './Components/Wellness';

function App() {

  return (
    <div className="flex container mx-auto h-screen relative">
      <div className='absolute pt-3 pl-3 '>
        <img className='w-14 lg:w-16' src={RageLogo} alt="rage fitness" />
      </div>
      <div className='absolute pt-7 pr-3 right-0'>
        <img className='w-32 md:w-40 lg:w-44' src={TSALogo} alt="tsa" />
      </div>
      <div className='p-10 flex justify-center align-middle items-center w-full'>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Wellness />} />
            <Route exact path='/admin' element={<Admin />} />
          </Routes>
        </BrowserRouter>
      </div>
      <footer
        className="bg-gray-200
             text-xs text-gray-500 text-center 
             fixed
             inset-x-0
             bottom-0
             p-2">
        Powered by The Sustainability Academy {(new Date().getFullYear())}
      </footer>
    </div>
  );
}

export default App;